<template>
  <div class="operationCenter" :style="vueStyle">
    <div class="content">
      <div class="operationList">
        <div v-for="item in operationList" :key="item.key" class="item" :class="{'noBorderBottom':item.noBorderBottom}"
             @click="toNext(item)"
        >
          <img :src="item.img" alt="" class="itemImg">
          <div class="name">{{ item.name }}</div>
          <div class="operationNumber" :style="{ 'background-image': 'url(' + operationNumber + ')' }"
               v-if="item.number>0"
          >
            <div class="value">
              {{ item.number }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customizeClick: {
      default: false
    },
    showBgImage: {
      default: true
    },
    operationList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {},
  computed: {
    vueStyle() {
      let style = {}
      if (this.showBgImage) {
        style['background-image'] = 'url(' + this.bgImg + ')'
      }
      return style
    }
  },
  data() {
    return {
      bgImg: require('../../../static/iconImage/operationCenter.png'),
      operationNumber: require('../../../static/iconImage/operationNumber.png')
    }
  },
  created() {
  },
  methods: {
    toNext(item) {
      if (this.customizeClick) {
        this.$bus.emit('clickOperationCenterItem', item)
        return
      }
      this.$push(item.path, {
        key: item.key
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@styles/variables.scss";

.operationCenter {
  min-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;

  .content {
    padding-top: 110px;
    width: 336px;
    margin: 0 auto;

    .operationList {
      background: $background_color2;
      border-radius: 12px;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      .item {
        width: 50%;
        height: 136px;
        text-align: center;
        border-right: 1px solid #eeebeb;
        border-bottom: 1px solid #eeebeb;
        position: relative;

        &:nth-child(2n) {
          border-right: 0 none;
        }

        &.noBorderBottom {
          border-bottom: 0 none;
        }

        .itemImg {
          margin-top: 34px;
          width: 48px;
          height: 48px;
        }

        .name {
          font-size: 18px;
          font-weight: 500;
          color: $color1;
          margin-top: 8px;
        }

        .operationNumber {
          position: absolute;
          top: 24px;
          right: 30px;
          width: 40px;
          height: 20px;
          background-size: 100%;
          color: #ffffff;
          font-size: 12px;

          .value {
            height: 20px;
            left: 50%;
            top: 50%;
            position: relative;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
</style>