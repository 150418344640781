<template>
  <operationCenter ref="operationCenter" :operationList="operationList"></operationCenter>
</template>
<script>
import operationCenter from "@/components/operationCenter/index.vue";
export default {
  components: { operationCenter },
  data() {
    return {
      operationList: [
        {
          path: "deviceBorrow/borrowTabsPage",
          img: require("../../../../../static/iconImage/operationReservation.png"),
          name: "我的预约",
          number: 0,
          key: "booked",
        },
        {
          path: "deviceBorrow/borrowTabsPage",
          img: require("../../../../../static/iconImage/operationScanning.png"),
          name: "扫码借用",
          number: 0,
          key: "passed",
        },
        {
          path: "deviceBorrow/borrowTabsPage",
          img: require("../../../../../static/iconImage/operationDisinfect.png"),
          name: "保养消毒",
          number: 0,
          key: "uncleaned",
        },
        {
          path: "deviceBorrow/borrowTabsPage",
          img: require("../../../../../static/iconImage/operationGiveBack.png"),
          name: "归还",
          number: 0,
          key: "revert",
        },
        {
          path: "deviceBorrow/borrowTabsPage",
          img: require("../../../../../static/iconImage/operationFailure.png"),
          name: "报障",
          number: 0,
          key: "unusable",
          noBorderBottom: true,
        },
      ],
    };
  },

  mounted() {
    this.addLocalStorageEventByKey("allDeviceUpdate", this.getCount);
    this.getCount();
  },
  beforeDestroy() {
    this.removeLocalStorageEvent(this.getCount);
  },
  methods: {
    getCount() {
      this.$showLoading({
        target: ".operationList",
      });
      this.$api.deviceBorrowModule
        .count3({ startTime: "", endTime: "" })
        .then((res) => {
          let _data = res.data;
          this.operationList.map((item) => {
            item.number = _data[item.key];
            return item;
          });
          this.$hideLoading({
            target: ".operationList",
          });
        })
        .catch((e) => {
          this.$hideLoading({
            target: ".operationList",
          });
        });
    },
  },
};
</script>
<style lang='scss' scoped>
</style>